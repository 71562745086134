import { css } from 'styled-components'
import { media } from './utils' 

const type = {
    heading1: css`
        font-family: 'Cinetype';
        font-size: 35px;
        line-height: 45px;

        ${media.phone`
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.2px;            
        `}
    `,
    heading2: css`
        font-family: 'Cinetype';
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.2px;

        ${media.phone`
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.1px;        
        `}
    `,
    heading3: css`
        font-family: 'Cinetype';
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.1px;

        ${media.phone`
            font-size: 14px;
            line-height: 19px;        
            letter-spacing: 0.1px;
        `}
    `,
    heading4: css`
        font-family: 'Cinetype';
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.1px;
    `,
    body: css`
        font-family: 'Cinetype';
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.2px;

        ${media.phone`
            font-family: 'Cinetype';
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.1px;
        `}
    `,
}

export default type