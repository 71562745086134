import React, { useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { isClient } from '../../styles/utils'

export const AcknowledgementContext = React.createContext()

const AcknowledgementProvider = ({ children }) => {
    const [active, setActive] = useState(true)
    const [scroll, setScroll] = useState(false)
    const [scrolledPast, setScrolledPast] = useState(false)
    // console.log('acknowledgement active', active)
    // console.log('acknowledgement scroll', scroll)
    // console.log('acknowledgement scrolled past', scrolledPast)

    // useMount(() => {
    //     if (sessionStorage.seenAcknowledgement === 'true') {
    //         setActive(false)
    //     }
    // })

    // useEffect(() => {
    //     if (!active) {
    //         sessionStorage.seenAcknowledgement = 'true'
    //     }
    // }, [active])

    return (
        <AcknowledgementContext.Provider
            value={{
                scroll: scroll,
                setScroll: setScroll,
                active: active,
                setActive: setActive,
                scrolledPast: scrolledPast,
                setScrolledPast: setScrolledPast
            }}
        >
            {children}
        </AcknowledgementContext.Provider>
    )
}

export default AcknowledgementContext

export { AcknowledgementProvider }
