import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';

import globalType from './type'
import globalColours from './colours';

export const maxWidth = 2560;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        ${globalType.body};
        
        &, * {
            color: black;
            font-family: 'Cinetype', sans-serif;
            font-weight: 400;
        }
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
    }

    b, strong {
        font-weight: 400;
    }

    * {
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }

    p {
		margin: 0;
	}

    .desktop {
        ${media.phone`
            display: none!important;
        `}
    }

    .phone {
        display: none!important;

        ${media.phone`
            display: flex!important;
        `}
    }
`

export const container = css`
    width: 100%;
    margin: 0 auto;
	width: ${maxWidth}px;
    max-width: 100%;
`

export const hoverState = css`
    transition: 0.25s opacity ease;

    ${media.phone`
        transition: none;
    `}

    &:hover {
        opacity: 0.7;

        ${media.phone`
            opacity: initial;
        `}
    }
`

export const padding = css`
    padding-left: 30px; 
    padding-right: 30px;
    box-sizing: border-box;

    ${media.phone`
        padding-left: 18px;
        padding-right: 18px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType