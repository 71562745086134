import React from 'react'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';
import { MenuProvider } from './src/components/Menu/MenuProvider';
import { AcknowledgementProvider } from './src/components/Acknowledgement/AcknowledgementProvider'

export default ({ element, props  }) => {
    return (
        <>
            <GlobalStyles/>
            <AcknowledgementProvider>
                <MenuProvider>
                    {element}
                </MenuProvider>
            </AcknowledgementProvider>
        </> 
    )
}