import React, { useState } from 'react'
import { useToggle } from 'react-use'

export const MenuContext = React.createContext()

const MenuProvider = ({ children }) => {
    const [active, toggleActive] = useToggle(false)

    return (
        <MenuContext.Provider value={{ active: active, toggleActive: toggleActive }}>
            {children}
        </MenuContext.Provider>
    )
}

export default MenuContext

export { MenuProvider }